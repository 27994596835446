<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="customerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
        v-if="customerData"
        pills
    >

      <!-- Tab: Customer -->
      <b-tab active>
        <template #title>
          <feather-icon
              icon="ClipboardIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Customer Information') }}</span>
        </template>
        <user-edit-tab-account
            :customer-data="customerData"
            class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Adress -->
      <b-tab>
        <template #title>
          <feather-icon
              icon="MapPinIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Adress Information') }}</span>
        </template>
        <user-edit-tab-adress :address="address" class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Personal Information -->
      <b-tab>
        <template #title>
          <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Personal Information') }}</span>
        </template>
        <user-edit-tab-personel class="mt-2 pt-75"/>
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Bank Information') }}</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75"/>
      </b-tab>
    </b-tabs>
    <!-- Action Buttons -->
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
        @click="CustomerAdd()"
    >
      {{ $t('Save') }}
    </b-button>
    <b-button class="mt-1"
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="CustomerReset()"
    >
      {{ $t('Reset') }}
    </b-button>
  </component>
</template>

<script>
import {
  BButton,
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../storeModule'
import UserEditTabAccount from './TabAccount.vue'
import UserEditTabPersonel from './TabPersonel.vue'
import UserEditTabSocial from './TabBank.vue'
import UserEditTabAdress from './TabAdress.vue'
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    UserEditTabAccount,
    UserEditTabPersonel,
    UserEditTabSocial,
    UserEditTabAdress,
  },
  data() {
    return {
      id: this.$route.params.id,
      AllData: [],
      customerData: {
        name: '',
        fax: '',
        vkn: '',
        tckn: '',
        email: '',
        phone: '',
        shortName: '',
        taxOffice: '',
        secretInfo: '',
        customerNumber: '',
        maturityOption: '',
      },
      customerAddresses: [],
      customerOfficials: [],
      customerBanks: [],
      customerDataTckn:'',
      config: {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("accessToken")}` ,
          Accept: 'application/json',
        }
      },
    }
  },
  setup() {
    const customerData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', {id: router.currentRoute.params.id})
        .then(response => {
          customerData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            customerData.value = undefined
          }
        })
  },
  mounted() {
    this.getCustomerInfo();
  },
  methods: {
    getCustomerInfo() {
        this.$http.get(`${config.API_BASE}/api/Customers/Getbyid?id=${this.id}`, this.config).then((response) => {
          console.log("bakAdmin", response.data)
          this.customerData=response.data
        });
    },
  },
}
</script>

<style>

</style>
