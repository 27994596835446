<template>
  <div>
    <div class="d-flex">
      <feather-icon
        icon="CreditCardIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{$t('Bank')}}
      </h4>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mt-1"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{$t('New Bank')}}</span>
    </b-button>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form mt-1"
        @submit.prevent="repeateAgain"
      >

        <!-- Bank List -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('Bank')"
              label-for="postcode"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="banklisted"
                :clearable="false"
                input-id="language"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('Bank Branch')"
              label-for="postcode"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="languageOptions"
                :clearable="false"
                input-id="language"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Address Title -->
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('Account Number')"
              label-for="address-line-1"
            >
              <b-form-input
                id="address-line-1"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Counrty -->
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              label="IBAN"
              label-for="address-line-2"
            >
              <b-form-input
                id="address-line-2"
                placeholder="Los Santos"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            cols="12"
            md="2"
            lg="2"
          >
            <div class="demo-inline-spacing">
              <div>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="deleteAdress(index)"
                >
                  {{$t('Delete Bank')}}
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>

  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

const config = require("@/config");



export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    deleteAdress(index) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete it!'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.items.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
          this.$swal({
            icon: 'success',
            title: this.$t('Deleted!'),
            text: this.$t('Your bank has been deleted.'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    CustomerList() {
      this.$http.get(`${config.API_BASE}/api/Customers/getall`)
        .then(response => {
          const { data } = response
          this.bankList = response.data
          console.log('data', data)
        })
    },
    BankBranchList() {
      this.$http.get(`${config.API_BASE}/api/BankBranches/getall`)
          .then(response => {
            const { data } = response
            this.bankBranchList = response.data
            console.log('data', data)
          })
    },
  },
  setup() {
    const userDataSocial = {
      twitter: 'https://www.twitter.com/adoptionism744',
      facebook: 'https://www.facebook.com/adoptionism664',
      instagram: 'https://www.instagram.com/adopt-ionism744',
      github: 'https://www.github.com/madop818',
      codepen: 'https://www.codepen.com/adoptism243',
      slack: '@adoptionism744',
    }

    const banklisted = [
      'Garanti',
      'Finans',
    ]
    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
      {
        icon: 'GithubIcon',
        dataField: 'github',
        label: 'github',
      },
      {
        icon: 'CodepenIcon',
        dataField: 'codepen',
        label: 'codepen',
      },
      {
        icon: 'SlackIcon',
        dataField: 'slack',
        label: 'slack',
      },
    ]
    const bankList = [
      {
        name: 'Garanti',
      },
      {
        name: 'Finans',
      },
      {
        name: 'Garanti',
      },
    ]
    const bankBranchList = [
      {
        name: 'Garanti',
      },
    ]

    return {
      userDataSocial,
      socialInputs,
      bankList,
      banklisted,
      bankBranchList,
    }
  },
}
</script>

<style>

</style>
