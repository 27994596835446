<template>
  <div>
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{$t('Personal')}}
      </h4>
    </div>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span>{{$t('New Personal')}}</span>
    </b-button>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form mt-1"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Field: Customer Personel Full Name  -->
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('Full Name')"
              label-for="full-name-1"
            >
              <b-form-input
                id="full-name-1"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Customer Personel Email  -->
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('E-Mail')"
              label-for="full-name-1"
            >
              <b-form-input
                id="email-e-1"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Customer Personel Phone  -->
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-form-group
              :label="$t('Phone')"
              label-for="full-name-1"
            >
              <b-form-input
                id="phone-e-1"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Customer Personel Info  -->
          <b-col
            cols="12"
            md="10"
            lg="8"
          >
            <b-form-group
              :label="$t('Info')"
              label-for="full-name-1"
            >
              <b-form-input
                id="info-e-1"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            cols="12"
            md="3"
            lg="3"
          >
            <div class="demo-inline-spacing">
              <div>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="deleteAdress(index)"
                >
                  {{$t('Delete Personal')}}
                </b-button>
              </div>
            </div></b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>

    <!-- Header: Personal Info -->


  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, vSelect, BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    deleteAdress(index) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete it!'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.items.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
          this.$swal({
            icon: 'success',
            title: this.$t('Deleted!'),
            text: this.$t('Your personal has been deleted.'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
